<template>
    <Modal :scrollable="true">
        <div class="venue-modal-container">
            <div class="top-bar">
                <img src="@/assets/logo_primary.svg" alt="logo" />
                <button class="button button-close" @click.stop="$emit('close')" />
            </div>
            <h2 v-if="step === 0">Choose a venue</h2>
            <FilterBar
                v-if="step === 0"
                :text="`${filteredVenues.length} venue${filteredVenues.length === 1 ? '' : 's'}`"
                :style="{ margin: '0 1rem' }"
            />
            <ProfileSelect
                v-if="step === 0"
                :value="selected"
                :profile-list="filteredVenues"
                :loading="$apollo.queries.findEventVenue.loading"
                @update="selected = $event"
                @step="step += 1"
            />
            <div v-if="step === 1" class="add-message">
                <div class="add-message__avatars">
                    <img
                        v-for="profile in selected"
                        :key="profile.id"
                        :src="profile.avatar"
                        class="avatar-xsm"
                        alt="avatar"
                    />
                </div>
                <h2>Write an initial message</h2>
                <form class="gb-form" @submit.prevent="submitHandler">
                    <fieldset class="gb-form__fieldset">
                        <BaseInput
                            v-model.number="price"
                            type="number"
                            placeholder="Price offer"
                            text-align="center"
                            required
                        />
                    </fieldset>
                    <fieldset class="gb-form__fieldset">
                        <Textarea v-model="initialMessage" placeholder="Initial message" required />
                    </fieldset>
                    <fieldset class="gb-form__fieldset">
                        <Button type="submit" primary label="Send request"></Button>
                        <Button label="Go back" @click.stop="step--"></Button>
                    </fieldset>
                </form>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal'
import Textarea from '@/components/Textarea'
import ProfileSelect from '@/components/eventrequest/ProfileSelect'
import FilterBar from '@/components/eventrequest/FilterBar'
import { FIND_EVENT_VENUE, MY_EVENT } from '@/graphql/queries/event.js'
import logger from '@/utils/logger.js'
import { CREATE_USER_EVENTREQUEST } from '@/graphql/mutations/eventRequest.js'
export default {
    name: 'ChooseVenuesModal',
    components: {
        Modal,
        ProfileSelect,
        Textarea,
        FilterBar
    },
    props: {
        blacklist: {
            type: Array,
            default: () => []
        },
        eventId: {
            type: String,
            required: true
        },
        eventLocation: {
            type: Object,
            default: () => ({ lat: 60.3925, long: 5.3233 })
        }
    },
    apollo: {
        findEventVenue: {
            query: FIND_EVENT_VENUE,
            variables() {
                return {
                    where: {
                        location: {
                            lat: this.eventLocation.lat,
                            long: this.eventLocation.long
                        }
                    }
                }
            }
        }
    },
    emits: ['close'],
    data() {
        return {
            step: 0,
            findEventVenue: [],
            selected: [],
            price: '',
            initialMessage: ''
        }
    },
    computed: {
        filteredVenues() {
            return this.findEventVenue.filter((venue) => !this.blacklist.includes(venue.id))
        }
    },
    methods: {
        async submitHandler() {
            try {
                await this.$apollo.mutate({
                    mutation: CREATE_USER_EVENTREQUEST,
                    variables: {
                        input: {
                            // createEventrequestInput
                            data: {
                                // EventrequestInput
                                event: this.eventId,
                                venueData: {
                                    message: this.initialMessage,
                                    price: this.price,
                                    venues: [this.selected.map((venue) => venue.id)]
                                }
                            }
                        }
                    },
                    refetchQueries: [{ query: MY_EVENT, variables: { id: this.eventId } }]
                })
                this.$emit('close')
            } catch (error) {
                logger.log(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
.venue-modal-container {
    background-color: $light;
    width: calc(100vw);
    min-height: calc(100vh);
    padding: 1rem 0;
    display: grid;
    align-content: start;
    text-align: center;

    h2 {
        padding: 1rem;
    }
    .top-bar {
        display: grid;
        align-items: center;
        justify-self: stretch;
        grid-template-columns: 1fr auto 1fr;
        padding: 1rem;
        & > :first-child {
            grid-column-start: 2;
        }
        & > :last-child {
            justify-self: end;
        }
    }

    .list {
        display: grid;
        row-gap: 1rem;
        // overflow: auto;
    }
}

.add-message {
    width: 100%;
    min-height: 100%;
    display: grid;
    justify-items: center;
    form {
        width: 100%;
    }
}
</style>
