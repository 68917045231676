<template>
    <div class="event text-primary bg-light">
        <div class="event__header bg-white">
            <h3 class="font-weight-medium">Requested</h3>
            <button
                class="button button-close"
                :style="{ cursor: 'pointer' }"
                @click="$router.go(-1)"
            ></button>
        </div>
        <Tabs buttons inline bg-white class="event__tabs">
            <Tab title="Venue" selected>
                <div v-if="acceptedVenues.length" class="event__request-list">
                    <RequestBox
                        v-for="request in acceptedVenues"
                        :key="request.id"
                        :request="request"
                        :event="myEvent"
                    />
                </div>
                <div v-if="pendingVenues.length" class="event__request-list">
                    <RequestBox
                        v-for="request in pendingVenues"
                        :key="request.id"
                        :request="request"
                        :event="myEvent"
                    />
                </div>
                <div v-if="$apollo.loading" class="event__request-list">
                    <LoadingEllipsis>Fetching venues</LoadingEllipsis>
                </div>
                <div
                    v-else-if="!pendingVenues.length && !acceptedVenues.length"
                    class="event__request-list"
                >
                    You have not requested any venues for this event.
                </div>

                <Button
                    label="Find Venues"
                    :style="{ margin: 'auto' }"
                    :disabled="$apollo.queries.myEvent.loading"
                    square
                    size="small"
                    @click="showFindVenueModal = true"
                />
                <ChooseVenuesModal
                    v-if="showFindVenueModal"
                    :event-id="myEvent.id"
                    :event-location="{
                        lat: myEvent.location.coordinates[1],
                        long: myEvent.location.coordinates[0]
                    }"
                    :blacklist="allVenueIds"
                    @close="showFindVenueModal = false"
                />
            </Tab>
            <Tab title="Artist">
                <div v-if="acceptedArtists.length" class="event__request-list">
                    <RequestBox
                        v-for="request in acceptedArtists"
                        :key="request.id"
                        :request="request"
                        :event="myEvent"
                    />
                </div>
                <div v-if="pendingArtists.length" class="event__request-list">
                    <RequestBox
                        v-for="request in pendingArtists"
                        :key="request.id"
                        :request="request"
                        :event="myEvent"
                    />
                </div>

                <div v-if="$apollo.loading" class="event__request-list">
                    <LoadingEllipsis>Fetching artists</LoadingEllipsis>
                </div>
                <div
                    v-else-if="!pendingArtists.length && !acceptedArtists.length"
                    class="event__request-list"
                >
                    You have not requested any artists for this event.
                </div>
                <Button
                    label="Find Artists"
                    :style="{ margin: 'auto' }"
                    :disabled="$apollo.queries.myEvent.loading"
                    square
                    size="small"
                    @click="showFindArtistModal = true"
                />
                <ChooseArtistsModal
                    v-if="showFindArtistModal"
                    :event-id="myEvent.id"
                    :event-location="{
                        lat: myEvent.location.coordinates[1],
                        long: myEvent.location.coordinates[0]
                    }"
                    :blacklist="allArtistIds"
                    @close="showFindArtistModal = false"
                />
            </Tab>
        </Tabs>
    </div>
</template>

<script>
import Tabs from '@/components/layout/Tabs'
import Tab from '@/components/layout/Tab'
import ChooseVenuesModal from '@/components/modals/ChooseVenuesModal'
import ChooseArtistsModal from '@/components/modals/ChooseArtistsModal'
import RequestBox from './RequestBox'
import { MY_EVENT } from '@/graphql/queries/event'

export default {
    name: 'Event',
    components: {
        Tabs,
        Tab,
        RequestBox,
        ChooseVenuesModal,
        ChooseArtistsModal
        // ChooseVenue
    },
    apollo: {
        myEvent: {
            query: MY_EVENT,
            variables() {
                return {
                    id: this.$route.params.id
                }
            }
        }
    },
    data() {
        return {
            myEvent: {
                eventrequests: []
            },
            showFindVenueModal: false,
            showFindArtistModal: false
        }
    },
    computed: {
        allVenueIds() {
            return this.myEvent.eventrequests
                .filter((request) => request.venue)
                .map((request) => request.venue.id)
        },
        allArtistIds() {
            return this.myEvent.eventrequests
                .filter((request) => request.artist)
                .map((request) => request.artist.id)
        },
        pendingVenues() {
            return this.myEvent.eventrequests
                .filter((request) => !!request.venue)
                .filter((request) => request.status !== 'ACCEPTED')
        },
        acceptedVenues() {
            return this.myEvent.eventrequests
                .filter((request) => !!request.venue)
                .filter((request) => request.status === 'ACCEPTED')
        },
        pendingArtists() {
            return this.myEvent.eventrequests
                .filter((request) => !!request.artist)
                .filter((request) => request.status !== 'ACCEPTED')
        },
        acceptedArtists() {
            return this.myEvent.eventrequests
                .filter((request) => !!request.artist)
                .filter((request) => request.status === 'ACCEPTED')
        }
    }
}
</script>

<style lang="scss">
@import '@/sass/variables.scss';

h3 {
    margin: 0;
}

// .modal {
//     width: 100vw;
//     height: 100vh;
//     overflow: hidden;
//     display: grid;
//     .button-wrapper {
//         height: 75px;
//         padding: 1rem;
//         display: grid;
//         justify-items: end;
//     }
//     &-scroll {
//         overflow: auto;
//         padding-bottom: 200px;
//     }
// }
.event {
    min-height: 100vh;

    .tabs__button:not(.tabs__button--primary) {
        background: $light;
        color: $primary;
        border: $light;
    }

    &__header {
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 3rem 1rem 1rem;
    }

    &__tabs {
        // padding: 0 1rem;
        > ul.tabs__list {
            padding: 0 1rem 1rem;
            font-size: 5rem;
        }
    }

    &__request-list {
        padding: 1rem 0.5rem;
        display: grid;
        row-gap: 0.5rem;
    }
}
</style>
